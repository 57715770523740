import React from 'react'
import Header from '../header/Header'
import Box from '@mui/material/Box'
import { basePadding, responsiveTypographyH3, responsiveTypographyH5 } from '../../constants'
import Divider from '@mui/material/Divider'
import { ListItem, ListItemText, Typography } from '@mui/material'

export default function ExperienceAndSkills() {
  return (
    <Box>
      <Header />
      <Box sx={{ ...basePadding }}>
        <Typography sx={{ ...responsiveTypographyH3 }}>Experience</Typography>
        <Divider sx={{ borderBottomWidth: 5 }} />
        <ListItem>
          <ListItemText
            primary={'March 2022 - Present'}
            secondary={
              <React.Fragment>
                <Typography sx={{ ...responsiveTypographyH5 }}>AMTOSS, Remote - Software Engineer</Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={'May 2021 - March 2022'}
            secondary={
              <React.Fragment>
                <Typography sx={{ ...responsiveTypographyH5 }}>
                  SoftFormance, Remote - Middle Backend Python/Django Developer
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={'February 2019 - April 2021'}
            secondary={
              <React.Fragment>
                <Typography sx={{ ...responsiveTypographyH5 }}>
                  Owlab, Poltava - Backend Python/Django Developer
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <Typography sx={{ ...responsiveTypographyH3, pt: '2%' }}>Skills</Typography>
        <Divider sx={{ borderBottomWidth: 5 }} />
        <ListItem>
          <ListItemText primary={'Python'} />
        </ListItem>
        <ListItem>
          <ListItemText primary={'OOP'} />
        </ListItem>
        <ListItem>
          <ListItemText primary={'Django'} />
        </ListItem>
        <ListItem>
          <ListItemText primary={'Django REST Framework'} />
        </ListItem>
        <ListItem>
          <ListItemText primary={'Docker'} />
        </ListItem>
        <ListItem>
          <ListItemText primary={'Celery'} />
        </ListItem>
        <ListItem>
          <ListItemText primary={'PostgreSQL'} />
        </ListItem>
        <ListItem>
          <ListItemText primary={'Git'} />
        </ListItem>
      </Box>
    </Box>
  )
}
