export const responsiveTypographyH3 = {
  typography: {
    xxs: 'h5', // small phone
    xs: 'h5', // phone
    sm: 'h4', // tablets
    md: 'h4', // small laptop
    lg: 'h3', // desktop
    xl: 'h3', // large screens
  },
}

export const responsiveTypographyH2 = {
  typography: {
    xxs: 'h4', // small phone
    xs: 'h4', // phone
    sm: 'h3', // tablets
    md: 'h3', // small laptop
    lg: 'h2', // desktop
    xl: 'h2', // large screens
  },
}

export const responsiveTypographyH5 = {
  typography: {
    xxs: 'h6', // small phone
    xs: 'h6', // phone
    sm: 'h5', // tablets
    md: 'h5', // small laptop
    lg: 'h5', // desktop
    xl: 'h5', // large screens
  },
}

export const basePadding = {
  ml: '8%',
  mr: '8%',
  mt: '2%',
}
