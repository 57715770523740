import React from 'react'
import Header from '../header/Header'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { basePadding, responsiveTypographyH2, responsiveTypographyH3, responsiveTypographyH5 } from '../../constants'
import { ListItem, ListItemText } from '@mui/material'

export default function Home() {
  const getYearsExperience = () => {
    const startedWork = new Date('2019-02-01T00:00:00Z')
    const today = new Date()
    const difference = (today.getTime() - startedWork.getTime()) / 31536000000
    return Math.floor(difference)
  }

  return (
    <Box>
      <Header />
      <Box sx={{ ...basePadding }}>
        <Typography sx={{ textAlign: 'end', ...responsiveTypographyH3 }}>Welcome</Typography>
        <Typography sx={{ textAlign: 'end', ...responsiveTypographyH2 }}>I'm Oleh Kazaiev</Typography>
        <Typography sx={{ textAlign: 'end', ...responsiveTypographyH2 }}>Software Engineer</Typography>
        <Typography variant="h5" sx={{ mt: '4%', ...responsiveTypographyH5 }}>
          Backend developer with over {getYearsExperience()} years of experience
          <br />
          Experienced with:
          <br />
          <ListItem sx={{ pt: 0, pb: 0, ml: '2%' }}>
            <ListItemText sx={{ display: 'list-item' }} primary={'Django and Django REST Framework.'} />
          </ListItem>
          <ListItem sx={{ pt: 0, pb: 0, ml: '2%' }}>
            <ListItemText
              sx={{ display: 'list-item' }}
              primary={'Analysis, design, and development of Backend and REST API.'}
            />
          </ListItem>
          <ListItem sx={{ pt: 0, pb: 0, ml: '2%' }}>
            <ListItemText sx={{ display: 'list-item' }} primary={'Database design.'} />
          </ListItem>
          <ListItem sx={{ pt: 0, pb: 0, ml: '2%' }}>
            <ListItemText sx={{ display: 'list-item' }} primary={'Integration with other services.'} />
          </ListItem>
          <ListItem sx={{ pt: 0, pb: 0, ml: '2%' }}>
            <ListItemText sx={{ display: 'list-item' }} primary={'Celery for periodic and asynchronous tasks.'} />
          </ListItem>
        </Typography>
      </Box>
    </Box>
  )
}
