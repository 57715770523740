import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Home from './home/Main'
import ExperienceAndSkills from './experienceAndSkills/ExperienceAndSkills'

const router = createBrowserRouter([
  {
    path: '/experience-and-skills',
    element: <ExperienceAndSkills />,
  },
  {
    path: '*',
    element: <Home />,
  },
])

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
})

function App() {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </React.StrictMode>
  )
}

export default App
