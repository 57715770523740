import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { Link } from '@mui/material'
import GitHubIcon from '@mui/icons-material/GitHub'
import EmailIcon from '@mui/icons-material/Email'

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window
}

const drawerWidth = 240

export default function Header(props: Props) {
  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <List>
        <ListItem key={'home'} disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }} href="/">
            <ListItemText primary={'Home'} />
          </ListItemButton>
        </ListItem>
        <ListItem key={'experience-and-skills'} disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }} href="/experience-and-skills">
            <ListItemText primary={'Experience and Skills'} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <Typography variant="h6" sx={{ my: 2 }}>
        <Link href="https://linkedin.com/in/oleh-kazaiev" sx={{ ml: '5%', mr: '5%' }}>
          <LinkedInIcon />
        </Link>
        <Link href="https://github.com/TheJog5505" sx={{ ml: '5%', mr: '5%' }}>
          <GitHubIcon />
        </Link>
        <Link href="mailto:olehkazaiev@gmail.com" sx={{ ml: '5%', mr: '5%' }}>
          <EmailIcon />
        </Link>
      </Typography>
    </Box>
  )

  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: 'none', sm: 'block' },
            }}
          >
            <Link href="https://linkedin.com/in/oleh-kazaiev" sx={{ display: 'inline', ml: '1%', mr: '1%' }}>
              <LinkedInIcon />
            </Link>
            <Link href="https://github.com/TheJog5505" sx={{ display: 'inline', ml: '1%', mr: '1%' }}>
              <GitHubIcon />
            </Link>
            <Link href="mailto:olehkazaiev@gmail.com" sx={{ display: 'inline', ml: '1%', mr: '1%' }}>
              <EmailIcon />
            </Link>
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Button key={'home'} sx={{ color: '#fff' }} href="/">
              {'Home'}
            </Button>
            <Button key={'experience-and-skills'} sx={{ color: '#fff' }} href="/experience-and-skills">
              {'Experience and Skills'}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Toolbar />
    </Box>
  )
}
